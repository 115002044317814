<template>
  <div class="g-input" :class="[variant, { hasIcon: passwordReveal || clearable }]">
    <template v-if="type !== 'textarea'">
      <!-- TODO Add Autofocus prop -->
      <input
        ref="input"
        :type="newType"
        :autocomplete="newAutocomplete"
        :maxlength="maxlength"
        v-bind="{ disabled: fieldDisabled.value, ...$attrs }"
        v-model="computedValue"
        v-on="listeners"
        class="input-element"
        :class="[{ error: fieldError.value }]"
        :required="required"
      />
      <span class="input-action" v-if="passwordReveal && type === 'password'" @click="togglePasswordVisibility">
        <!-- TODO figure out why I have to define svg sizes here. I shouldn't have to here only on symbol -->
        <svg width="24" height="24" viewBox="0 0 24 24">
          <use v-if="isPasswordVisible" href="#eye-off" />
          <use v-else href="#eye" />
        </svg>
      </span>
      <span class="input-action" v-else-if="clearable" @click="newValue = ''">
        <svg width="24" height="24" viewBox="0 0 24 24">
          <use href="#close-x" />
        </svg>
      </span>
    </template>

    <textarea
      v-else
      :rows="autoResize ? 1 : null"
      ref="textarea"
      class="input-element"
      :class="[{ error: fieldError.value, autoResize }]"
      :maxlength="maxlength"
      v-bind="{ disabled: fieldDisabled.value, ...$attrs }"
      v-on="listeners"
      v-model="computedValue"
    />

    <small v-if="maxlength && showCount && type !== 'number'">
      <slot name="count" v-bind="{ characterCount, maxLength: maxlength, charactersLeft }">
        <template v-if="countType === 'descriptive'">
          {{ charactersLeft }} character{{ charactersLeft === 1 ? '' : 's' }} left
        </template>
        <template v-else> {{ characterCount }} / {{ maxlength }} </template>
      </slot>
    </small>
    <!-- ICONS -->
    <!-- Kind of experiementing. 1 svg 3 icons. Could also import as component with v-show="false" but this allows for no assets -->
    <svg v-show="false" xmlns="http://www.w3.org/2000/svg">
      <symbol id="eye" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"
        />
      </symbol>
      <symbol id="eye-off" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M11.885 14.988l3.104-3.098.011.11c0 1.654-1.346 3-3 3l-.115-.012zm8.048-8.032l-3.274 3.268c.212.554.341 1.149.341 1.776 0 2.757-2.243 5-5 5-.631 0-1.229-.13-1.785-.344l-2.377 2.372c1.276.588 2.671.972 4.177.972 7.733 0 11.985-8.449 11.985-8.449s-1.415-2.478-4.067-4.595zm1.431-3.536l-18.619 18.58-1.382-1.422 3.455-3.447c-3.022-2.45-4.818-5.58-4.818-5.58s4.446-7.551 12.015-7.551c1.825 0 3.456.426 4.886 1.075l3.081-3.075 1.382 1.42zm-13.751 10.922l1.519-1.515c-.077-.264-.132-.538-.132-.827 0-1.654 1.346-3 3-3 .291 0 .567.055.833.134l1.518-1.515c-.704-.382-1.496-.619-2.351-.619-2.757 0-5 2.243-5 5 0 .852.235 1.641.613 2.342z"
        />
      </symbol>
      <symbol id="close-x" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
        />
      </symbol>
    </svg>
  </div>
</template>

<script>
/**
 * This will handle most standard inputs. Its mostly just a wrapper for styling, + a few other baked in features. For now radio and checkboxes will live in their own components<br>
 * visit <a href="#gradio">For radio buttons</a><br>
 * visit <a href="#gcheckbox">For checkboxes</a>
 * @version 0.1.1
 */

import fieldMixin from '@/components/local_modules/utils/fieldMixin';
import baseComponentMixin from '@/components/local_modules/utils/baseComponentMixin';

export default {
  name: 'GInput',
  inheritAttrs: false,
  mixins: [fieldMixin, baseComponentMixin],
  inject: {
    fieldError: {
      default: { value: false },
    },
  },
  props: {
    /** @model */
    value: [Number, String],
    /**
     * @values Any native input type, and textarea
     */
    type: {
      type: String,
      default: 'text',
    },

    variant: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    /**
     * Adds the reveal password functionality
     */
    passwordReveal: {
      type: Boolean,
    },
    /**
     * Show character counter when maxlength prop is passed
     */
    showCount: {
      type: Boolean,
      default: false,
    },
    /**
     * outOf: x / maxLength<br>
     * descriptive: x character(s) left
     */
    countType: {
      type: String,
      default: 'outOf',
      validator: (value) => ['outOf', 'descriptive'].includes(value),
    },
    /** Add a button/icon to clear the inputed text */
    clearable: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [Number, String],
    },
    // TODO decide if content editable would be a better use for something like this
    /** setting this on a text area will allow it to auto resize as you type */
    autoResize: {
      type: Boolean,
    },
    required: Boolean,
  },
  data() {
    return {
      newValue: this.value,
      newType: this.type,
      newAutocomplete: this.autocomplete,
      isPasswordVisible: false,
      error: this.fieldError?.value || false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          if (this.autoResize) {
            this.resize();
          }
          this.$emit('input', event.target.value);
          this.$emit('updated', event);
        },
      };
    },
    elementRef() {
      return this.type === 'textarea' ? 'textarea' : 'input';
    },
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        if (this.type === 'number') {
          this.newValue = Number(value);
        }
        this.$emit('input', this.newValue);
      },
    },
    /**
     * Get value length
     */
    characterCount() {
      if (typeof this.computedValue === 'string') {
        return this.computedValue.length;
      }
      if (typeof this.computedValue === 'number') {
        return this.computedValue.toString().length;
      }
      return 0;
    },
    charactersLeft() {
      if (this.maxlength && this.characterCount < this.maxlength) return this.maxlength - this.characterCount;
      return 0;
    },
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set internal value.
     */
    value: {
      immediate: true,
      handler(value) {
        this.newValue = value;
      },
    },
  },
  methods: {
    /**
     * Toggle the visibility of a password-reveal input
     * by changing the type and focus the input right away.
     */
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.newType = this.isPasswordVisible ? 'text' : 'password';

      this.$nextTick(() => {
        this.$refs[this.elementRef].focus();
      });
    },
    resize() {
      const el = this.$refs[this.elementRef];
      const style = window.getComputedStyle(el);
      el.style.height = 'auto';
      el.style.overflow = 'hidden';
      el.style.height = `${el.scrollHeight}px`;
      if (parseFloat(el.style.height) >= parseFloat(style.maxHeight)) {
        el.style.overflowY = 'auto';
        el.style.height = style.maxHeight;
        el.scrollTop = el.scrollHeight;
      }
    },
  },
  mounted() {
    if (this.autoResize && this.type === 'textarea') {
      this.resize();
    }
  },
};
</script>

<style lang="scss" scoped>
.g-input {
  position: relative;

  .input-action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .input-element {
    box-sizing: border-box;
    width: 100%;
    padding: 16px 12px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 300;
    // color: $primary-white;
    // background-color: $transparent;
    // border: 1px solid $primary-white;
    color: #222;
    background-color: #fff;
    border: 2px solid #222;
    // TODO Figure out solution for autofill styling on login page
    // Chrome webkit autofill style touch up
    // &:-webkit-autofill,
    // &:-webkit-autofill:hover,
    // &:-webkit-autofill:focus {
    //   border: none;
    //   border-bottom: 1px solid $input-border-light;
    //   -webkit-box-shadow: none;
    //   transition: background-color 5000s ease-in-out 0s;
    //   -webkit-text-fill-color: $text-color-dark;
    // }
    &:not([type='submit'], [type='range']) {
      appearance: none;
    }
    &::placeholder {
      color: inherit;
      // opacity: 0.5;
    }
    &:disabled {
      opacity: 0.5;
    }
    &.error {
      border: 1px solid red;
      &:focus {
        border-color: red;
      }
    }
  }
  textarea {
    &.autoResize {
      resize: none;
    }
  }
  input:-webkit-autofill::first-line {
    font-family: inherit;
  }
}
</style>
